export const LOGIN = "LOGIN";
export const LOGIN_PENDING = "LOGIN_PENDING";
export const LOGIN_FULFILLED = "LOGIN_FULFILLED";
export const LOGIN_REJECTED = "LOGIN_REJECTED";

export const LOGOUT = "LOGOUT";

export const EDIT_PROFILE = "EDIT_PROFILE";
export const EDIT_PROFILE_PENDING = "EDIT_PROFILE_PENDING";
export const EDIT_PROFILE_FULFILLED = "EDIT_PROFILE_FULFILLED";
export const EDIT_PROFILE_REJECTED = "EDIT_PROFILE_REJECTED";

export const NEW_PROFILE = "NEW_PROFILE";
export const NEW_PROFILE_PENDING = "NEW_PROFILE_PENDING";
export const NEW_PROFILE_FULFILLED = "NEW_PROFILE_FULFILLED";
export const NEW_PROFILE_REJECTED = "NEW_PROFILE_REJECTED";

export const UPLOAD_FILE = "UPLOAD_FILE";

export const FETCH_ALL_PROFILES = "FETCH_ALL_PROFILES";
export const FETCH_ALL_PROFILES_PENDING = "FETCH_ALL_PROFILES_PENDING";
export const FETCH_ALL_PROFILES_FULFILLED = "FETCH_ALL_PROFILES_FULFILLED";
export const FETCH_ALL_PROFILES_REJECTED = "FETCH_ALL_PROFILES_REJECTED";

export const STORE_SEARCH_INPUT = "STORE_SEARCH_INPUT";
